import { ReactNode } from "react";

import { Center, ChakraProvider, HStack, Text } from "@chakra-ui/react";
import dynamic from "next/dynamic";
import Head from "next/head";
import Link from "next/link";

import theme from "@styles/theme";

const DynamicVConsole = dynamic(
  () => import("@components/VConsoleComponent/VConsoleComponent"),
  {
    ssr: false,
  }
);
const Layout = ({ children }: { children: ReactNode }) => (
  <>
    <Head>
      <title>中和石油</title>
      <link rel="icon" href="/favicon.ico" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="anonymous"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@200..900&display=swap"
        rel="stylesheet"
      />
    </Head>
    <ChakraProvider theme={theme}>
      {children}
      <footer style={{ width: "100%", marginTop: 5, marginBottom: 100 }}>
        <Center>
          <HStack>
            <Link href="/privacy-policy" data-test="privacyPolicyLink">
              <Text fontSize="xs">プライバシーポリシー</Text>
            </Link>
            <Link href="/term-of-use" data-test="termOfUseLink">
              <Text fontSize="xs">利用規約</Text>
            </Link>
          </HStack>
        </Center>
      </footer>
    </ChakraProvider>
    <DynamicVConsole />
  </>
);

export default Layout;
