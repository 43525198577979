import { extendTheme } from "@chakra-ui/react";

const colors = {
  line: {
    500: "#00B900",
    600: "#049105",
  },
  text: {
    100: "#e4e8e9",
    200: "#ccd1d5",
    300: "#a8b1b8",
    400: "#7d8a93",
    500: "#626f78",
    600: "#545e66",
    700: "#495057",
    800: "#40454a",
    900: "#383b41",
  },
  keroseneMain: {
    500: "#EB1414",
    600: "#D00B0B",
  },
  keroseneBlack: {
    500: "#1A1A1A",
    600: "#000000",
  },
};
const theme = extendTheme({
  components: {
    Heading: {
      baseStyle: {
        color: colors.text["900"],
      },
    },
    Text: {
      baseStyle: {
        color: colors.text["800"],
      },
      variants: {
        secondary: {
          color: colors.text["700"],
        },
      },
    },
  },
  colors,
});

export default theme;
