import {
  Box,
  Card,
  CardBody,
  Center,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useRouter } from "next/router";

import Layout from "@layouts/Layout";
import { NextPageWithLiff } from "@pages/_app";

const Home: NextPageWithLiff = () => {
  const router = useRouter();
  const cards = [
    {
      label: "お客様情報フォーム",
      onClick: () => router.push("/form/client-info"),
      body: "お客様情報のご登録はこちら",
    },
    {
      label: "ご予約",
      onClick: () => router.push("appointment-menu"),
      body: "各種メニューのご予約はこちら",
    },
    {
      label: "ご予約確認・変更・キャンセル",
      onClick: () => router.push("appointment"),
      body: "現在のご予約内容についての確認はこちら",
    },
  ];
  return (
    <Center px={2}>
      <VStack w="full">
        <Heading>中和石油</Heading>
        {cards.map((card) => (
          <Box cursor="pointer" w="100%" key={card.label}>
            <Card
              variant="outline"
              onClick={card.onClick}
              w="100%"
              _hover={{ bg: "gray.50" }}
            >
              <CardBody>
                <Heading size="md" mb={3}>
                  {card.label}
                </Heading>
                <Text variant="secondary">{card.body}</Text>
              </CardBody>
            </Card>
          </Box>
        ))}
      </VStack>
    </Center>
  );
};

Home.getLayout = (page) => <Layout>{page}</Layout>;
export default Home;
